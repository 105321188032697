<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="300px">
    <v-card>
      <v-toolbar color="indigo">
        <v-textarea
          solo
          rounded
          flat
          placeholder="Cari"
          dense
          row-height="6"
          :auto-grow="true"
          hide-details
          v-model="searched"
        >
          <v-icon slot="append" color="grey"> mdi-magnify </v-icon>
        </v-textarea>

        <v-btn icon @click="$emit('false')" dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list dense>
        <v-list-item-group color="primary">
          <div v-for="item in list_contact" :key="item.id">
            <v-list-item
              @click="$emit('pickContact', item)"
              v-if="item.id !== user_id"
            >
              <v-list-item-avatar color="indigo">
                <span class="white--text font-weight-bold" v-if="item.nama">
                  {{ item.nama[0].toUpperCase() }}
                </span>
                <!-- <span class="white--text font-weight-bold">
                  {{
                  item.user_id
                }}
                </span> -->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.nama"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="
                    item.role_id == '4'
                      ? 'Peserta'
                      : item.role_id == '3'
                      ? 'Coach'
                      : 'Widyaiswara'
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "listcontact",
  computed: {
    ...mapState(["group_id", "user_id", "list_contact"])
  },
  props: ["dialog"],
  data() {
    return {
      mentor: "mentor",
      student: "Peserta",
      grupById: null,
      role: "",
      searched: "",
    };
  },
  mounted(){
    // this.getContact();
  },
  methods:{
    // getContact(){
    //   this.$store.dispatch("listContact", {id:this.user_id, search:this.searched});
    // },
  },
};
</script>

<style></style>
