<template>
  <div class="d-flex container-chat">
    <div class="list-chat">
      <div class="head-chat pa-2">
        <v-textarea
          solo
          rounded
          placeholder="Cari"
          dense
          flat
          height="1"
          hide-details
          no-resize
          v-model="searched"
        >
          <v-icon slot="append" color="grey"> mdi-magnify </v-icon>
        </v-textarea>
      </div>
      <div>
        <div>
          <section
            class="item pa-2 d-flex justify-space-between"
            v-for="list in list_chat"
            :key="list.id"
            @click="fromListChat(list)"
          >
            <div class="d-flex align-center ma-1" v-if="list.grup_id">
              <v-avatar color="indigo" size="40" class="mr-2">
                <span class="white--text font-weight-bold">{{
                  list.nama_grup[0].toUpperCase()
                }}</span>
              </v-avatar>
              <section class="d-flex">
                <h4 class="mr-2">{{ list.nama_grup }}</h4>
                <v-divider vertical inset></v-divider>
                <small class="ml-2 d-flex align-center">kelompok</small>
              </section>
            </div>
            <div class="d-flex align-center ma-1" v-else>
              <v-avatar color="indigo" size="40" class="mr-2">
                <span class="white--text font-weight-bold">{{
                  list.member[0].nama[0].toUpperCase()
                }}</span>
              </v-avatar>
              <section>
                <h4>{{ list.member[0].nama }}</h4>
              </section>
            </div>
            <div>
              <section class="badges" v-if="list.unread > 0">
                <small>
                  {{ list.unread }}
                </small>
              </section>
            </div>
          </section>
        </div>
        <v-tooltip top max-width="200px" nudge-top="5px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="indigo"
              dark
              class="overflow-btn"
              @click="dialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-chat-plus </v-icon>
            </v-btn>
          </template>
          <span>Cari kontak</span>
        </v-tooltip>
        <div v-if="!loading">
          <ListContact
            v-bind:dialog="dialog"
            @false="dialogFalse(1)"
            @pickContact="fromListContact"
            v-bind:user_id="user_id"
          />
        </div>
      </div>
    </div>
    <div class="discuss" v-if="people">
      <div class="head-chat">
        <div class="d-flex align-center ma-1" v-if="people.grup_id">
          <v-avatar color="indigo" size="35" class="mr-2">
            <span class="white--text font-weight-medium">{{
              people.nama_grup[0].toUpperCase()
            }}</span>
          </v-avatar>
          <h5 class="mr-2">{{ people.nama_grup }}</h5>
          <v-divider vertical inset></v-divider>
          <small class="ml-2">kelompok</small>
        </div>
        <div class="d-flex align-center ma-1" v-else>
          <v-avatar color="indigo" size="35" class="mr-2">
            <span class="white--text font-weight-medium">{{ people.ava }}</span>
          </v-avatar>
          <h5>{{ people.nama ? people.nama : people.member[0].nama }}</h5>
        </div>
      </div>
      <div v-if="chatLoad" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          size="30"
          color="indigo"
        ></v-progress-circular>
      </div>
      <div class="temp-chat" v-else>
        <div v-if="allDataChat != null">
          <div class="chat" v-if="allDataChat.pesan">
            <div
              id="more"
              class="d-flex justify-center mb-2"
              v-if="allDataChat.pesan.next_page_url"
            >
              <v-btn depressed color="indigo" dark @click="loadMore"
                >muat lainnya</v-btn
              >
            </div>
            <div v-for="(msg, idx) in sortMsg" :key="idx">
              <div v-if="idx == 0">
                <section class="_xday d-flex justify-center">
                  <p
                    class="_sz ma-0"
                    v-if="moment(msg.created_at).format('DD-MM-YYYY') == today"
                  >
                    Today
                  </p>
                  <p
                    class="ma-0 _sz"
                    v-else-if="
                      moment(msg.created_at).format('DD-MM-YYYY') == yesterday
                    "
                  >
                    Yesterday
                  </p>
                  <p class="_sz ma-0" v-else>
                    {{ moment(msg.created_at).format("DD-MM-YYYY") }}
                  </p>
                </section>
              </div>
              <div v-else>
                <div
                  v-if="
                    moment(sortMsg[idx].created_at).format('DD-MM-YYYY') !==
                    moment(sortMsg[idx - 1].created_at).format('DD-MM-YYYY')
                  "
                >
                  <section class="_xday d-flex justify-center">
                    <p
                      class="_sz ma-0"
                      v-if="
                        moment(msg.created_at).format('DD-MM-YYYY') == today
                      "
                    >
                      Today
                    </p>
                    <p
                      class="_sz ma-0"
                      v-else-if="
                        moment(msg.created_at).format('DD-MM-YYYY') == yesterday
                      "
                    >
                      Yesterday
                    </p>
                    <p class="_sz ma-0" v-else>
                      {{ moment(msg.created_at).format("DD-MM-YYYY") }}
                    </p>
                  </section>
                </div>
              </div>
              <div
                :class="msg.user_id == user_id ? 'temp-me' : 'temp-you'"
                :id="`d${msg.id}b`"
              >
                <div :class="msg.user_id == user_id ? 'half-me' : 'half-you'">
                  <div
                    :class="msg.user_id == user_id ? 'ballon-me' : 'ballon-you'"
                  >
                    <div>
                      <div v-if="msg.reply_to">
                        <div
                          :class="
                            msg.user_id == user_id ? 'repl-you' : 'repl-me'
                          "
                          @click="scrollTo(msg.reply_to.id)"
                        >
                          <p class="_sz ma-0">
                            <b>{{ msg.reply_to.pengirim.nama_lengkap }}</b>
                          </p>
                          <p class="_me">{{ msg.reply_to.message }}</p>
                        </div>
                      </div>
                      <small>{{ msg.pengirim.nama_lengkap }}</small>
                      <p class="_me">{{ msg.message }}</p>
                    </div>
                    <div
                      class="d-flex flex-column justify-space-between align-end"
                    >
                      <section class="_opt">
                        <v-menu offset-y>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn x-small v-bind="attrs" v-on="on" icon>
                              <v-icon> mdi-chevron-down </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item link @click="reply(msg)">
                              <v-list-item-title>
                                <small class="px-4">Balas</small>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item link>
                              <v-list-item-title>
                                <small class="px-4" @click="deleteChat(msg)"
                                  >Hapus</small
                                >
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click.stop="dialogs = true">
                              <v-list-item-title>
                                <small class="px-4">Info pesan</small>
                              </v-list-item-title>
                            </v-list-item>
                            <InfoMsg
                              v-bind:dialogs="dialogs"
                              :msg="msg"
                              @false="dialogFalse(2)"
                            />
                          </v-list>
                        </v-menu>
                      </section>
                      <small
                        :class="
                          msg.user_id === user_id
                            ? ' grey--text d-flex align-end'
                            : ' white--text d-flex align-end'
                        "
                      >
                        {{ moment(msg.created_at).format("HH:mm") }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-center">{{ allDataChat }}</p>
          </div>
        </div>
      </div>
      <form @submit.prevent="sendMsg">
        <div class="temp-input">
          <div class="add-file">
            <v-btn
              class="mx-2"
              depressed
              fab
              dark
              small
              color="indigo"
              @click="dialogFileIndividu"
            >
              <v-icon small dark> mdi-plus </v-icon>
            </v-btn>
          </div>
          <FileIndividu
            v-bind:dialog_file="dialog_file"
            v-on:refetch="refetchFile"
            v-if="dialog_file"
            v-on:false="closeDialog()"
          />
          <div class="text-temp"> 
            <div class="_repl px-2">
              <v-alert border="left" elevation="2" class="py-1 my-2">
                <div class="d-flex justify-space-between">
                  <section>
                    <h5 class="indigo--text">
                      {{ sender }}
                    </h5>
                    {{ txtReply }}
                  </section>
                  <section class="d-flex align-center">
                    <v-btn icon small @click="cancelReply">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </section>
                </div>
              </v-alert>
            </div>
            <v-textarea
              id="myInput"
              solo
              rounded
              v-model="message"
              flat
              placeholder="Ketik pesan"
              dense
              hide-details
              row-height="7"
              :auto-grow="true"
              @keyup.enter="sendMsg"
            ></v-textarea>
          </div>
          <div class="temp-btn">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              depressed
              color="indigo"
              type="sendMsg"
            >
              <v-icon small dark> mdi-send </v-icon>
            </v-btn>
          </div>
        </div>
      </form>
    </div>
    <div class="discuss" v-else>
      <div class="temp-chat d-flex align-center justify-center">
        <h3 class="text-center" style="width: 50%">
          Silakan pilih kontak untuk memulai percakapan
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GROUP_BY_ID } from "../../graphql/Event";
import { MY_FILE } from "../../graphql/Student";
import ListContact from "../../components/_base/listContact";
import InfoMsg from "../../components/_base/infoMsg";
import FileIndividu from "../../components/Student/file/fileIndividu";

import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "discussion",
  apollo: {
    grupById: {
      variables() {
        return { id: this.group_id };
      },
      query: GROUP_BY_ID,
      error(error) {
        console.log(error);
      },
    },
    fileByUser: {
      variables() {
        return { user_id: this.user_id };
      },
      skip() {
        return !this.user_id;
      },
      query: MY_FILE,
      result(data) {
        this.$store.commit("MYFILE", data.data.fileByUser);
      },
      error(error) {
        console.log(error);
      },
    },
  },
  computed: {
    sortMsg() {
      const sor = [...this.allChat].reverse();
      return sor;
    },

    ...mapState([
      //ambil state dari vuex (store)
      "group_id",
      "user_id",
      "list_chat",
      "allChat",
      "allDataChat",
      "list_contact",
      "thread_id",
      "link_file",
      "url",
      "fileTipe",
    ]),
    loading() {
      //loading for idk, aing forgot hehehe
      return this.$apollo.queries.grupById.loading;
    },
  },
  components: {
    ListContact,
    InfoMsg,
    FileIndividu,
  },
  data() {
    return {
      dialog: false,
      grupById: null,
      people: null,
      searched: "",
      message: "",
      moment: moment,
      txtReply: "",
      replyId: null,
      dialogs: false,
      unRead: null,
      chatLoad: false,
      today: moment().format("DD-MM-YYYY"),
      yesterday: moment().subtract(1, "days").format("DD-MM-YYYY"),
      sender: "",
      page: 1,
      dialog_file: false,
      activePage: 0,
    };
  },
  created() {
    // const id = localStorage.getItem("id");
    // const vm = this;
    // window.Echo.channel(`private-chat-${id}`).listen("ChatEvent", (e) => {
    //   console.log(e.chat.thread_id);
    //   if (e) {
    //     if (e.chat.thread_id == vm.activePage) {
    //       this.$store
    //         .dispatch("fetchChat", {
    //           id: e.chat.thread_id,
    //           page: 1,
    //         })
    //         .then(() => {
    //           setTimeout(function () {
    //             let scrollDown = document.querySelector(".temp-chat");
    //             scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
    //           }, 100);
    //         });
    //     }
    //     else {
    //       this.getChat();
    //     }
    //   }
    // });
  },
  mounted() {
    if (this.user_id) {
      this.$store.dispatch("listContact", this.user_id); //get list contact
    }
    this.getChat()
  },
  methods: {
    getChat(){
      this.$store.dispatch("listChat", {search:this.searched})
      .then((data) => {
      this.unRead = data.data
        .map((el) => {
          return parseInt(el.unread);
        })
        .reduce((a, b) => a + b, 0); //menjumlahkan pesan yang belum terbaca
      });
          this.$store.commit('UNREAD', this.unRead);
    },
    dialogFileIndividu() {
      this.dialog_file = true;
    },
    //load morreeee
    loadMore() {
      this.page = this.page + 1;
      this.$store
        .dispatch("fetchChat", {
          id: this.thread_id,
          page: this.page,
        })
        .then((data) => {
          console.log(data.data.pesan.data.length);
        });
    },
    //memunculkan pesan yang di reply
    reply(msg) {
      this.sender = msg.pengirim.nama_lengkap;
      this.txtReply = msg.message;
      this.replyId = msg.id;
      let repl = document.querySelector("._repl");
      repl.style.display = "block";
      document.getElementById("myInput").focus();
    },
    //close pesan yang di reply
    cancelReply() {
      this.replyId = null;
      let repl = document.querySelector("._repl");
      repl.style.display = "none";
      document.getElementById("myInput").focus();
    },
    //mengirim pesan
    sendMsg() {
      let id = this.people.thread_id ? this.people.thread_id : 0; //cek jika thread id ga ada maka set 0
      let reply = this.replyId ? this.replyId : 0; //kalo dia ga reply, set 0
      let recipient = this.people.user_id
        ? this.people.user_id
        : this.people.member[0].id; //cek penerima dari list thread apa list contact
      let data = new FormData();
      data.append("thread_id", id);
      data.append("penerima", recipient);
      data.append("pesan", this.message);
      data.append("reply_to", reply);
      if (this.message == "") {
        //do stuff if message are empty, apa ke..
        console.log("pesan kosong");
      } else {
        this.$store.dispatch("sendChat", data).then((data) => {
          reply = null;
          this.message = "";
          this.people.thread_id = data.data.data.thread_id;
          this.activePage = data.data.data.thread_id;
          this.getChat();
          this.$store
            .dispatch("fetchChat", {
              id: data.data.data.thread_id,
              page: 1,
            })
            .then((data) => {
              console.log(data.data.pesan.data.length);
              setTimeout(function () {
                let scrollDown = document.querySelector(".temp-chat");
                scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
              }, 100);
            });
          this.cancelReply();
          document.getElementById("myInput").focus();
        });
      }
    },
    deleteChat(msg) {
      console.log(msg.id);
      this.$store.dispatch("deleteChat", msg).then((data) => {
        this.$store.dispatch("fetchChat", { id: msg.thread_id, page: 1 });
        Swal.fire({
          icon: "success",
          title: data.data.message,
          showConfirmButton: false,
          timer: 3000,
          position: "top",
          toast: true,
        });
      });
    },
    //select ppl from list/thread chat
    fromListChat(people) {
      this.page = 1;
      let firstStr = people.member[0].nama.split(" ");
      firstStr = firstStr[0].toString().split("")[0];
      people.ava = firstStr.toUpperCase();
      let id = people.thread_id;
      this.people = people;
      if (people.thread_id != this.activePage) {
        this.chatLoad = true;
        this.activePage = people.thread_id;
        this.$store
          .dispatch("fetchChat", { id: id, page: this.page })
          .then(() => {
            this.chatLoad = false;
            this.getChat();
            setTimeout(function () {
              let scrollDown = document.querySelector(".temp-chat");
              scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
            }, 100);
            document.getElementById("myInput").focus();
          });
      }
      console.log(`from list chat`);
    },
    //select ppl from list contatc
    fromListContact(people) {
      this.page = 1;
      this.chatLoad = true;
      this.dialogFalse(1);
      let filterVelas = null;
      filterVelas = this.list_chat.filter((el) => {
        //cek apakah contact sudah ada di list thread
        return el.member[0].id == people.user_id;
      });
      console.log(filterVelas);
      if (filterVelas.length) {
        //kalo ada maka maka set id thread
        console.log(filterVelas[0].nama);
        this.people = filterVelas[0];
        let id = filterVelas[0].thread_id;
        this.$store
          .dispatch("fetchChat", { id: id, page: this.page })
          .then(() => {
            setTimeout(function () {
              let scrollDown = document.querySelector(".temp-chat");
              scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
            }, 100);
            this.chatLoad = false;
            document.getElementById("myInput").focus();
          });
        console.log(`from peoplevelas`);
      } else {
        //kalo ga ada maka set thread id = 0
        let firstStr = people.nama.split(" ");
        firstStr = firstStr[0].toString().split("")[0];
        people.ava = firstStr.toUpperCase();
        this.people = people;
        let id = 0;
        this.$store
          .dispatch("fetchChat", { id: id, page: this.page })
          .then(() => {
            this.chatLoad = false;
          });
        console.log(`from else `);
      }
    },
    //close dialog
    dialogFalse(q) {
      if (q === 1) {
        this.dialog = false;
      } else {
        this.dialogs = false;
      }
    },
    //scroll ke pesan yang di reply
    scrollTo(idMsg) {
      let idmsg = document.getElementById(`d${idMsg}b`);
      let more = document.getElementById(`more`);
      let elmnt = idmsg ? idmsg : more;
      elmnt.style.background = "#b6c0ce";
      let sec = 1;
      elmnt.scrollIntoView();
      let min = setInterval(() => {
        sec--;
        console.log(sec);
        if (sec === 0) {
          elmnt.style.background = "none";
          clearInterval(min);
        }
      }, 1000);
    },
    refetchFile(evt) {
      this.$apollo.queries.fileByUser.refetch();
      console.log(evt);
    },
    closeDialog() {
      this.dialog_file = false;
      const link = this.link_file;
      const url = this.url;
      console.log(this.fileTipe);
      if (this.fileTipe == "FILE") {
        this.message = link;
        console.log(link);
      } else {
        this.message = url;
        console.log(url);
      }
    },
  },
};
</script>

<style scoped>
.container-chat {
  height: 90vh;
  background: #f4f6f9;
}
.discuss {
  position: relative;
  width: 70%;
  background: #f4f6f9;
  box-sizing: border-box;
}
.temp-chat {
  width: 100%;
  height: 73vh;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.chat {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.temp-input {
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 15px;
  background: #f4f6f9;
}
.add-file {
  display: flex;
  justify-content: center;
  width: 7%;
}
.text-temp {
  width: 85%;
}
.temp-btn {
  display: flex;
  justify-content: center;
  width: 7%;
}
.temp-you {
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-bottom: 10px; */
  transition: 0.5s;
  border-radius: 5px;
}
.half-you {
  display: flex;
  justify-content: flex-start;
}
.temp-me {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
}
.half-me {
  display: flex;
  justify-content: flex-end;
}
.ballon-me {
  display: flex;
  background: white;
  padding: 5px 10px;
  border-radius: 10px 10px 0 10px;
}
.ballon-you {
  display: flex;
  background: #60c828;
  padding: 5px 10px;
  width: max-content;
  border-radius: 10px 10px 10px 0;
  color: white;
}
.repl-me {
  background: #5aa332;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  cursor: pointer;
}
.repl-you {
  background: #f0f0f0;
  padding: 5px 10px;
  width: max-content;
  border-radius: 5px;
  cursor: pointer;
}
.list-chat {
  position: relative;
  width: 30%;
  background: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
  border-right: 1px #eeeeee solid;
}
.head-chat {
  border-bottom: 1px #eeeeee solid;
  background: #f4f6f9;
  height: 8vh;
}
.item {
  border-bottom: 1px #eeeeee solid;
  cursor: pointer;
}
.item:hover {
  background: #f4f6f9;
}
.overflow-btn {
  position: sticky;
  z-index: 3;
  bottom: 25px;
  left: 80%;
}
._me {
  margin-bottom: 0;
  margin-right: 20px;
  word-wrap: break-word;
  max-width: 400px;
}

._opt {
  display: flex;
  justify-content: end;
  opacity: 0;
}

._opt:hover {
  opacity: 1;
  cursor: pointer;
}

._repl {
  display: none;
  transition: 5s;
  overflow: hidden;
}
.badges {
  height: 20px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: white;
  border-radius: 20px;
}
._sz {
  font-size: 14px;
}
</style>
