<template>
  <v-dialog v-model="dialogs" persistent max-width="300">
    <v-card>
      <v-toolbar color="indigo" dense>
        <span class="headline white--text">Info Pesan</span>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5">Pesan</v-col>
          <v-col cols="12" md="7">
            {{ msg.message }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">Pengirim</v-col>
          <v-col cols="12" md="7">
            {{ msg.pengirim.nama_lengkap }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5">Tersampaikan</v-col>
          <v-col cols="12" md="7">
            <section>
              {{ moment(msg.created_at).format("HH.ss") }}
            </section>
            <section>
              {{ moment(msg.created_at).format("DD/MM/YYYY") }}
            </section>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="$emit('false')">
          Keluar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";

export default {
  props: ["dialogs", "msg"],
  data() {
    return {
      moment: moment
    };
  }
};
</script>
